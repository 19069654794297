import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MentalAwaraness from '../../assets/images/course-cards/mental-awareness.svg';
import CloseEyeSvg from '../../assets/images/closed-eye.svg';
import OpenEyeSvg from '../../assets/images/open-eye.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  cursor: pointer;
  padding-bottom: 20px;
`;

const ImgWrapper = styled.img`
  margin: 0 0;
  padding: 0 0;
  width: 100%;
  height: auto;
`;

const DesctiptionWrapper = styled.div`
  padding: 0 16px 16px 16px;
  margin: 0 0;
  justify-content: flex-start;
  overflow: hidden;
`;

const Title = styled.p`
  margin: 0 0;
  padding: 10px 0 8px 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  width: 80%;
`;

const Subtitle = styled.p`
  margin: 0 0;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  color: #808080;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const TitleAndStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ClosedEye = styled.div`
  margin: auto 0;
  width: 32px;
  height: 32px; //35
  border-radius: 25px;
  display: flex;
  justify-content: center;
  border: 4px solid #350380;
  padding-top: 4px;
  position: absolute;
  top: 8px;
  right: -5px;
`;

const OpenEye = styled.div`
  width: 40px;
  height: 40px;
  padding: 0;
  background-size: cover;
  background-image: url(${OpenEyeSvg});
  position: absolute;
  top: 10px;
  right: -15px;
`;

export const LearningMaterialComponent = ({ material, onLearningMaterialClick }) => {
  const thumbnail =
    material.thumbnail && material.thumbnail.url ? material.thumbnail.url : MentalAwaraness;

  return (
    <Wrapper onClick={() => onLearningMaterialClick(material)}>
      <ImgWrapper src={thumbnail} />
      <DesctiptionWrapper>
        <TitleAndStatusWrapper>
          <Title>{material.topic}</Title>
          {material.watched ? (
            <OpenEye />
          ) : (
            <ClosedEye>
              <img alt="Not seen" src={CloseEyeSvg} />{' '}
            </ClosedEye>
          )}
        </TitleAndStatusWrapper>
        <Subtitle>{material.description}</Subtitle>
      </DesctiptionWrapper>
    </Wrapper>
  );
};

LearningMaterialComponent.props = {
  material: PropTypes.object.isRequired,
  onLearningClick: PropTypes.func.isRequired
};
