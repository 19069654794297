import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import { HomeHeader } from '../components/Home/HomeHeader';
import { CourseCard } from '../components/Home/CourseCard';
import { ProfileRightBlock } from '../components/Profile';
import { LearningPathList } from '../components/Home/LearningPathList';
import { Loading } from '../components/Loading';

import { useCoursesWithStats } from '../hooks/courseWithStats';
import { ratingType } from '../helpers/enums/ratingType';
import { GET_E_LEARNING_DATA } from '../helpers/graphqlQueries';


const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PageBody = styled.div`
  display: block;
  width: 70vw;
`;

const PageContent = styled.div`
  padding-left: 70px;

  width: 100%;
  margin: 0 auto;
  max-width: 1260px;
`;

const PageHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 24px;
`;

const PageDescription = styled.p`
  max-width: 1128px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px; // 32px is way too much, I've set it to 24px
  color: #000000;
  margin-bottom: 48px;
`;

const CourseList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 31px;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  margin: 0 0 35px 0;
`;

const HomeComponent = () => {
  const eLearningDataQuery = useQuery(GET_E_LEARNING_DATA);
  const coursesQuery = useCoursesWithStats();

  if (coursesQuery.loading || eLearningDataQuery.loading) return <Loading />;
  if (coursesQuery.error || eLearningDataQuery.error) return <p>error :(</p>;

  const { courses } = coursesQuery.data;
  const eLearningData = eLearningDataQuery.data.eLearningData[0];

  const state = {
    courses: courses,
    heading: eLearningData.title,
    description: eLearningData.description,
  };

  return (
    <PageWrapper>
      <PageBody>
        <HomeHeader />

        <PageContent className="page-content">
          <PageHeading>{state.heading}</PageHeading>
          <PageDescription>{state.description}</PageDescription>
          {eLearningData.videoUrl && <Video controls>
            <source
              src={eLearningData.videoUrl}
            />
          </Video>}
    
          <CourseList>
            {state.courses.map((course, i) => (
              <CourseCard
                key={i}
                active={course.active}
                type="focus"
                image={course.image}
                description={course.description}
                courseId={course.id}
                surveys={course.surveys}
                topic={course.topic}
              />
            ))}
          </CourseList>

          <LearningPathList courses={courses} />
        </PageContent>
      </PageBody>

      <ProfileRightBlock
        ratingData={{
          type: ratingType.HOME,
          data: {
            courses
          }
        }}
      />
    </PageWrapper>
  );
};

export const HomePage = withRouter(HomeComponent);
