import React from 'react';
import styled from 'styled-components';

import LeftArrow from '../../assets/images/arrow-left.svg';

export const RoundedButton = styled.button`
  width: 168px;
  height: 48px;
  background: #ceeb03;
  border-radius: 100px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const StandardButton = styled.button`
  width: 168px;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;

  img {
    margin-right: 10px;
  }

  color: #ceeb03;

  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 100%;
  letter-spacing: -0.02em;
`;

export const BackButton = props => (
  <StandardButton {...props}>
    <img src={LeftArrow} alt="" />
    Back
  </StandardButton>
);
