import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';

import { surveyStatus } from '../../helpers/enums/surveyStatus';

const HEADING_TEXT = 'Continue learning path';

const LearningPathWrapper = styled.div`
  margin-bottom: 95px;
`;

const Heading = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 133%;
  padding: 0;
  margin: 0 0 32px;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: inline-flex;
  align-items: center;
`;

const ListItem = styled.li`
  max-width: 356px;
  min-width: 356px;
  height: 190px;
  background: #fff;

  padding: 24px 24px 32px;
  margin-right: 24px;

  box-shadow: inset 0 5px #350380;
`;

const ItemHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;

  h3 {
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-family: Work Sans;
    font-style: normal;
    font-weight: 420;
    font-size: 1.2em;
    line-height: 133%;
    color: #000000;

    margin: 0;
  }
`;

const ItemDescription = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  margin: 0;
`;

const EyeIcon = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 4px solid #2d127a;
`;

const CourseProgress = styled.p`
  color: black;
  font-size: 1em;
  font-weight: 500;
  margin: auto auto;
  padding: 0 0;
`;

export const LearningPathList = ({ courses }) => (
  <LearningPathWrapper>
    <Heading>{HEADING_TEXT}</Heading>

    <List>
      {courses.map((course, i) => {
        let completedCount = 0;
        const notCompleted = course.surveys.find((survey, index) => {
          completedCount = index;
          return survey.status === surveyStatus.EVALUATED || survey.status === null;
        });

        return (
          notCompleted && course.active && (
            <Link
              key={i}
              smooth
              to={`/course/${course.id}#${notCompleted.id}`}
              style={{ textDecoration: 'none' }}
            >
              <ListItem key={course.id}>
                <ItemHeader>
                  <h3>{course.topic}</h3>
                  <EyeIcon>
                    <CourseProgress>
                      {completedCount}/{course.surveys.length}
                    </CourseProgress>
                  </EyeIcon>
                </ItemHeader>
                <ItemDescription>
                  Current topic: <br />
                  {notCompleted.topic}
                </ItemDescription>
              </ListItem>
            </Link>
          )
        );
      })}
    </List>
  </LearningPathWrapper>
);

LearningPathList.propTypes = {
  pathList: PropTypes.array
};
