import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { surveyStatus } from '../helpers/enums/surveyStatus';

import { Loading } from '../components/Loading';
import { Heading1, Heading2, Heading3, Text } from '../components/Shared/Typography';

import profile from '../helpers/profile/profileStore';
import { useCourseWithStats } from '../hooks/courseWithStats';

const LearningMaterialsCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
  & > div:last-child {
    margin-right: 0px;
  }
`;

const Wrapper = styled.div`
  box-shadow: 0px -4px 0px 0px #ceeb03;

  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 150px;
  width: 50%;

  cursor: pointer;
  padding-bottom: 20px;
`;

const DesctiptionWrapper = styled.div`
  padding: 16px 16px;
  margin: 0 0;
  justify-content: flex-start;
  overflow: hidden;
`;

const Page = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const CongratulationsContainer = styled.div`
  padding-top: 50px;
  width: 80vw;
  margin: auto auto;
  display: flex;
  flex-direction: column;
`;

const Summary = props => {
  const { surveyId, participantId, courseId } = props.match.params;

  const { loading, error, data } = useCourseWithStats(courseId, participantId);

  if (loading) return <Loading />;
  if (error) return <p>error</p>;

  const { course } = data;

  const currentSurvey = course.surveys.find(survey => survey.id === surveyId);

  if (currentSurvey.status !== surveyStatus.RE_EVALUATED) return <Redirect to="/" />;

  return (
    <Page>
      <CongratulationsContainer>
        <Heading1>{currentSurvey.topic} summary</Heading1>
        <Heading2>You’ve made a cool progress!</Heading2>
        {currentSurvey.summary && <Text>{currentSurvey.summary}</Text>}
        <br />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <LearningMaterialsCardsWrapper>
            <Heading3>Evaluate:</Heading3>

            <Wrapper>
              <DesctiptionWrapper>
                <Text>{currentSurvey.topic}</Text>
                <div style={{ display: 'flex' }}>
                  <Heading3>
                    {Math.round(profile.scoresArithmeticMean(currentSurvey.firstScore))}
                  </Heading3>
                  <Text style={{ paddingTop: 15, paddingLeft: 5 }}>points</Text>
                </div>
              </DesctiptionWrapper>
            </Wrapper>
          </LearningMaterialsCardsWrapper>

          <LearningMaterialsCardsWrapper>
            <Heading3>Re-evalute:</Heading3>

            <Wrapper>
              <DesctiptionWrapper>
                <Text>{currentSurvey.topic}</Text>
                <div style={{ display: 'flex' }}>
                  <Heading3>
                    {Math.round(profile.scoresArithmeticMean(currentSurvey.lastScore))}
                  </Heading3>
                  <Text style={{ paddingTop: 15, paddingLeft: 5 }}>points</Text>
                </div>
              </DesctiptionWrapper>
            </Wrapper>
          </LearningMaterialsCardsWrapper>
        </div>
      </CongratulationsContainer>
    </Page>
  );
};

export const SummaryPage = withRouter(Summary);
