// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap);"]);
// Module
exports.push([module.id, "html, body {\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  font-family: \"Work Sans\", sans-serif;\n  background: #FAFAFA;\n  font-size: 1em; }\n\n* {\n  box-sizing: border-box; }\n\n#root {\n  width: 100%;\n  height: 100%; }\n\nhtml, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {\n  font-family: \"Work Sans\", sans-serif;\n  font-style: normal;\n  font-weight: normal; }\n", ""]);
// Exports
module.exports = exports;
