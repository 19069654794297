import React from 'react';
import RadarChart from 'react-svg-radar-chart';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fromCamelCaseToNormal } from '../../helpers/stringUtils';

const Heading = styled.p`
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4em;
  line-height: 150%;
  color: #000000;
  margin: 0 0;
  margin-top: 50px;
`;

const RadarWrapper = styled.div`
  cursor: pointer;

  .dot {
    stroke: none;

    &.chart-0 {
      fill: #2d127a;
    }

    &.chart-1 {
      fill: #bee900;
    }
  }

  .scale {
    fill: none;
    stroke-width: 1;
    stroke: #000;
  }
`;

const getCaptionNames = radarData =>
  radarData.reduce(
    (radarData, currentChart) => ({
      ...radarData,
      ...Object.keys(currentChart.data).reduce(
        (captions, caption) => ({ ...captions, [caption]: fromCamelCaseToNormal(caption) }),
        {}
      )
    }),
    {}
  );

export const RadarChartComponent = ({ data, heading }) => {
  const getChartOptions = () => ({
    scales: 6,
    dots: true,
    captionMargin: 50,

    captionProps: () => ({
      className: 'caption',
      fontSize: 13,
      textAnchor: 'middle'
    }),
    dotProps: () => ({ className: 'dot' })
  });

  return (
    <RadarWrapper>
      {heading && <Heading>{heading}</Heading>}

      <RadarChart
        captions={getCaptionNames(data)}
        data={data}
        size={400}
        options={getChartOptions()}
      />

      <br />
    </RadarWrapper>
  );
};

RadarChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  heading: PropTypes.string
};

RadarChartComponent.defaultProps = {
  heading: ''
};
