import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import notifications from '../../assets/images/profile-block/notification.svg';
import settings from '../../assets/images/profile-block/settings.svg';
import signout from '../../assets/images/profile-block/sign-out.svg';

const ICON_IMAGE_LIST = {
  notifications,
  settings,
  signout
};

const Icon = styled.div`
  height: 16px;
  width: 16px;
  background-image: url(${$props => ICON_IMAGE_LIST[$props.image]});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

export const SettingsIcon = ({ type, onClick }) => <Icon image={type} onClick={onClick} />;

SettingsIcon.propTypes = {
  type: PropTypes.string.isRequired
};
