import React from 'react';
import styled from 'styled-components';

const QuestionWrapper = styled.div`
  background-color: #ffffff;
  margin: 0 0;
  margin-top: 1em;
  padding: 2em;
`;

const QuestionHeader = styled.p`
  margin: 0 0;
`;

const Answer = styled.button`
  color: ${$props => ($props.selected ? '#FFFFFF' : '#808080')};
  background-color: ${$props => ($props.selected ? '#CEEB03' : '#FFFFFF')};
  width: 10%;
  height: 3em;

  border: ${$props => ($props.selected ? 'none' : '1px solid rgba(0, 0, 0, 0.1)')};
  box-sizing: border-box;
  outline: none;
  border-left: 0;
`;

const AnswersWrapper = styled.div`
  margin-top: 1em;
  background: rgba(255, 255, 255, 0.1);

  button {
    box-sizing: border-box;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 450;
    font-size: 1em;
  }
  button:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const getAnswerRow = (selectedValue, changeValue) => {
  return Array.from({ length: 10 }, (_, answerIndex) => {
    const answerValue = answerIndex + 1;
    const selected = answerValue === selectedValue;

    return (
      <Answer key={answerValue} selected={selected} onClick={() => changeValue(answerValue)}>
        {answerValue}
      </Answer>
    );
  });
};

export const Question = ({ value, changeValue, questionText }) => (
  <QuestionWrapper>
    <QuestionHeader>{questionText}</QuestionHeader>
    <AnswersWrapper>{getAnswerRow(value, changeValue)}</AnswersWrapper>
  </QuestionWrapper>
);
