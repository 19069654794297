import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { ProfileRightBlock } from '../components/Profile';
import { CourseHeader } from '../components/Course/CourseHeader';
import { LearningCard } from '../components/Course/LearningCard';
import { Loading } from '../components/Loading';
import { VideoModal } from '../components/Course/VideoModal';
import { RoundedButton } from '../components/Button';

import FocusBg from '../assets/images/focus-bg.svg';

import profile from '../helpers/profile/profileStore';
import { addWatchedMaterial } from '../helpers/profile/statsService';
import { useCourseWithStats } from '../hooks/courseWithStats';

import { ratingType } from '../helpers/enums/ratingType';
import { surveyStatus } from '../helpers/enums/surveyStatus';

import { LearningMaterialsModal } from '../components/Course/LearningMaterialModal';
import { LearningMaterialsCarousel } from '../components/Course/LearningMaterials';
import { LearningVideosSection } from '../components/Course/LearningVideos';

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PageBody = styled.div`
  display: block;
`;

const PageContent = styled.div`
  position: relative;
  padding-top: 37px;
  padding-left: 70px;
  padding-right: 70px;
  width: 100%;
  margin: 0 auto;
  width: 75vw;
`;

const Heading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  color: #000000;
  margin-bottom: 28px;
`;

const BackgroundImage = styled.div`
  width: 735px;
  height: 496px;

  background-image: url(${props => (props.imageUrl ? props.imageUrl : FocusBg)});
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;
  top: -323px;
  right: -60px;
`;

const DescriptionWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 1120px;
  margin-bottom: 90px;
`;

const Description = styled.p`
  max-width: 510px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin: 0;
  padding: 0;
`;

const ReEvaluateWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ReEvaluateButton = styled(RoundedButton)`
  width: 168px;
  height: 48px;
`;

const LearningCardFullWrapper = styled.div`
  margin-bottom: 32px;
`;

const ScrollMarker = styled.div``;

const CourseComponent = props => {
  const courseId = props.match.params.id;

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [modalVideoData, setModalVideoData] = useState({});

  const [isLearningMaterialModalOpen, setIsLearningMaterialModalOpen] = useState(false);
  const [learningModalArticle, setLearningModalArticle] = useState('');

  const onLearningMaterialClick = material => {
    setIsLearningMaterialModalOpen(true);
    setLearningModalArticle(material);
  };

  const { loading, error, data, update } = useCourseWithStats(courseId);

  if (loading) return <Loading />;
  if (error) return <p>error</p>;

  const { course } = data;
  const surveysDataWithStats = course.surveys;

  const onVideoClick = (videoMaterials, isYoutubeVideo = false) => {
    setIsVideoModalOpen(true);
    setModalVideoData({ video: videoMaterials, isYoutubeVideo });
  };

  const surveysForRender = surveysDataWithStats.map((surveyData, i, surveyDataArray) => {
    const isFirstSurvey = i === 0;
    const isPrevSurveyReEvaluated =
      i > 0 && surveyDataArray[i - 1].status === surveyStatus.RE_EVALUATED;

    const isMaterialsWatched = surveyData?.watchedMaterials?.length >= 3;

    const nextStarted = surveyDataArray[i + 1]?.status !== null;
    const isLastItem = surveyDataArray.length === i + 1;

    const isEvaluated = surveyData.status === surveyStatus.EVALUATED;
    const isReEvaluated = surveyData.status === surveyStatus.RE_EVALUATED;

    const active = (isFirstSurvey || isPrevSurveyReEvaluated) && !isReEvaluated;

    const open = (!nextStarted || isLastItem) && surveyData.status !== null;
    const cardState = { open, isReEvaluated, active, isEvaluated, isMaterialsWatched };

    const surveyScore =
      surveyData.status === surveyStatus.EVALUATED
        ? profile.scoresArithmeticMean(surveyData.firstScore)
        : profile.scoresArithmeticMean(surveyData.lastScore);

    return { surveyData, surveyScore, cardState };
  });

  const beforeVideoModalClose = (clickedElement, videoData) => {
    if (clickedElement && !clickedElement.classList.contains('modal-background')) return;
    const surveyId = surveysDataWithStats.find(
      survey =>
        survey.videos.find(video => (video.id = videoData.id)) ||
        survey.youtubeVideos.find(video => video.id === videoData.id)
    ).id;

    addWatchedMaterial(profile.get().id, surveyId, courseId, videoData.id)
      .then(_ => update())
      .catch(error => console.error(error));

    setIsVideoModalOpen(false);
  };

  const beforeLearningMaterialModalClose = (clickedElement, materialData) => {
    if (clickedElement && !clickedElement.classList.contains('modal-background')) return;
    const surveyId = surveysDataWithStats.find(survey =>
      survey.learningMaterials.find(material => material.id === materialData.id)
    ).id;

    addWatchedMaterial(profile.get().id, surveyId, courseId, materialData.id)
      .then(_ => update())
      .catch(error => console.error(error));
    setIsLearningMaterialModalOpen(false);
  };

  const hasCourseCompleted = course.surveys.every(
    survey => survey?.status === surveyStatus.RE_EVALUATED
  );

  if (hasCourseCompleted) {
    window.location.replace(`/congratulations/${courseId}/${profile.get().id}`);
  }

  return (
    <PageWrapper>
      <VideoModal
        open={isVideoModalOpen}
        videoMaterials={modalVideoData.video}
        isYoutubeVideo={modalVideoData.isYoutubeVideo}
        onCloseClick={beforeVideoModalClose}
      />

      <LearningMaterialsModal
        open={isLearningMaterialModalOpen}
        onCloseClick={beforeLearningMaterialModalClose}
        material={learningModalArticle}
      />

      <PageBody>
        <CourseHeader title={course.topic} onBackClick={() => props.history.push('/')} />
        <PageContent className="page-content">
          <DescriptionWrapper>
            <Description>{course.description}</Description>
            <BackgroundImage imageUrl={course.image?.url} />
          </DescriptionWrapper>

          <Heading>Learning path</Heading>
          {surveysForRender.map(({ surveyData, cardState, surveyScore }, i) => {
            return (
              <LearningCardFullWrapper key={surveyData.key}>
                <ScrollMarker id={surveyData.id} />
                <LearningCard
                  survey={surveyData}
                  courseId={courseId}
                  order={i + 1}
                  cardState={cardState}
                />

                {cardState.open && (
                  <>
                    <Heading style={{ margin: 0 }}>
                      Learning. Watched {surveyData.watchedMaterials.length} out of 3 required{' '}
                    </Heading>
                    <LearningMaterialsCarousel
                      survey={surveyData}
                      onLearningMaterialClick={onLearningMaterialClick}
                    />
                    <LearningVideosSection
                      survey={surveyData}
                      surveyScore={surveyScore}
                      onVideoClick={onVideoClick}
                    />
                  </>
                )}

                {cardState.isEvaluated && cardState.active && (
                  <ReEvaluateWrapper>
                    {cardState.isMaterialsWatched && (
                      <Link
                        to={`/course/${courseId}/survey/${surveyData.id}`}
                        style={{ textDecoration: 'none', margin: '2em auto' }}
                      >
                        <ReEvaluateButton> Re-evalute </ReEvaluateButton>
                      </Link>
                    )}
                  </ReEvaluateWrapper>
                )}
              </LearningCardFullWrapper>
            );
          })}
        </PageContent>
      </PageBody>

      <ProfileRightBlock
        surveys={surveysDataWithStats}
        ratingData={{
          data: {
            surveys: surveysDataWithStats
          },
          type: ratingType.COURSE
        }}
        background={'gray'}
      />
    </PageWrapper>
  );
};

export const CoursePage = withRouter(CourseComponent);
