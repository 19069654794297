import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import FrameLeft from '../../assets/images/profile-block/profile-frame-left.svg';
import FrameRight from '../../assets/images/profile-block/profile-frame-right.svg';
import HandTop from '../../assets/images/profile-block/profile-hand-top.svg';
import HandBottom from '../../assets/images/profile-block/profile-hand-bottom.svg';

import DummyPicture from '../../assets/images/profile-block/dummy-profile-picture.png';
import { StarRating } from './StarRating';
import { SettingsIcon } from './SettingsIcon';
import { RadarChartComponent } from './RadarChart';
import { SocialIcon as Icon } from '../Social/Icon';
import { Tabs } from '@auth0/cosmos';

import { HEADER_HEIGHT } from '../../helpers/StyleConstants';
import { ratingType } from '../../helpers/enums/ratingType';
import { surveyStatus } from '../../helpers/enums/surveyStatus';
import { GET_LANDING_FOOTERS } from '../../helpers/graphqlQueries';
import profile from '../../helpers/profile/profileStore';

const NUMBER_OF_SOCIAL_ICONS = 3;
const NUMBER_OF_STARS = 6;
const formatScoreToStarsValue = arithmeticMeanOfScore =>
  arithmeticMeanOfScore * (NUMBER_OF_STARS * 0.1);

const BG_COLORS = {
  blue: '#2D127A',
  gray: '#808080'
};

const ProfileWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 25vw;
  background: #fff;
  position: relative;
`;

const ProfileHeader = styled.div`
  height: ${HEADER_HEIGHT};
  background: ${$props => BG_COLORS[$props.background]};
  position: relative;
  padding-top: 102px;
  text-align: center;
`;

const ProfileMain = styled.div`
  flex-grow: 1;
`;

const ProfileFooter = styled.div`
  flex-shrink: 0;
  text-align: right;
  width: 100%;
`;

const FooterContent = styled.div`
  padding: 0 40px;
`;

const ProfileBody = styled.div`
  padding: 0 0;
  text-align: center;
`;

const ProfilePicture = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 100px;
  background-color: #fff;
  position: relative;
  padding: 6px;
  z-index: 50;
  margin: 0 auto 32px;
`;

const ProfileHandTop = styled.img`
  position: absolute;
  width: 38px;
  height: 113px;

  top: -45px;
  left: -20px;

  &.right {
    transform: rotateY(180deg);

    left: unset;
    right: -20px;
  }
`;

const ProfileHandBottom = styled.img`
  position: absolute;
  width: 133px;
  height: 62px;

  top: 45px;
  left: -70px;

  &.right {
    transform: rotateY(180deg);

    left: unset;
    right: -70px;
  }
`;

const Avatar = styled.div`
  width: 108px;
  height: 108px;
  border-radius: 80px;
  background-image: url(${$props => $props.image});
  background-size: cover;
  z-index: 100;
  position: relative;
`;

const Border = styled.div`
  position: absolute;
  width: 120px;
  height: 121px;
  background-image: url(${$props => ($props.side === 'left' ? FrameLeft : FrameRight)});
  top: ${$props => ($props.side === 'left' ? '0px' : 'unset')};
  left: ${$props => ($props.side === 'left' ? '0px' : 'unset')};
  bottom: ${$props => ($props.side === 'right' ? '0px' : 'unset')};
  right: ${$props => ($props.side === 'right' ? '0px' : 'unset')};
`;

const NameWrapper = styled.div`
  margin: 0 auto 36px;
  text-align: center;
`;

const Name = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
  padding: 0;
`;

const Email = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${$props => ($props.color === 'blue' ? '#9688BC' : '#ABABAE')};
  margin: 0;
  padding: 0;
`;

const Separator = styled.hr`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
`;

const Heading = styled.p`
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 161%;
  color: #000000;
  margin: 0 0 16px;
`;

const SocialWrapper = styled.div`
  width: calc(40px * ${NUMBER_OF_SOCIAL_ICONS});
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 62px;
`;

const ContactWrapper = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  line-height: 29px;
  text-align: right;
  margin-bottom: 24px;

  p {
    margin: 0;
    padding: 0;
  }
`;

const Copyrights = styled.p`
  margin: 32px 40px;
  padding: 0;
`;

const SettingsWrapper = styled.div`
  width: 115px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  position: relative;
`;

const StyledTabs = styled(Tabs)`
  ul[data-cosmos-key='tabs.list'] {
    button {
      color: #2d127a;
      &[aria-selected='false'] {
        color: #808080;
      }
    }
  }

  text-align: center;

  & > ul {
    display: flex;
    justify-content: center;
  }
`;

const onExitClick = () => {
  localStorage.clear();
  window.location.replace(process.env.REACT_APP_NOT_AUTH_REDIRECT);
};



const StarsFromSurveysWithStats = ({ surveys }) => {
  return surveys.map(
    (survey, index) =>
      survey.status && (
        <StarRating
          key={index}
          label={survey.topic}
          editing={false}
          starNum={6}
          value={
            survey.status === surveyStatus.EVALUATED
              ? formatScoreToStarsValue(profile.scoresArithmeticMean(survey.firstScore))
              : formatScoreToStarsValue(profile.scoresArithmeticMean(survey.lastScore))
          }
        />
      )
  );
};

const RatingSection = ({ ratingData }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  switch (ratingData.type) {
    case ratingType.COURSE:
      return (
        <>
          <RadarChartComponent
            heading="Profile parts"
            data={profile.courseChartData(ratingData.data.surveys)}
          />
          <StarsFromSurveysWithStats surveys={ratingData.data.surveys} />
        </>
      );

    case ratingType.SURVEY:
      return (
        <RadarChartComponent
          heading="Profile parts"
          data={profile.chartDataFromSurvey(
            ratingData.data.surveyStats,
            ratingData.data.surveyScores
          )}
        />
      );

    case ratingType.HOME:
      return (
        <StyledTabs onSelect={nextIndex => setSelectedTab(nextIndex)} selected={selectedTab}>
          {ratingData.data.courses.map((course, i) => course.active && (
            <StyledTabs.Tab label={course.topic} key={i}>
              <RadarChartComponent data={profile.courseChartData(course.surveys)} />
              <StarsFromSurveysWithStats surveys={course.surveys} />
            </StyledTabs.Tab>
          ))}
        </StyledTabs>
      );

    default:
      return null;
  }
};

export const ProfileRightBlock = ({ background, ratingData }) => {
  const { data, error, loading } = useQuery(GET_LANDING_FOOTERS);

  if (!profile.isProfileLoggedIn()) return null;
  if (loading) return null;
  if (error) throw new Error(error);

  const { footer } = data.landings[0];
  const { name, email, avatar } = profile.get();

  return (
    <ProfileWrapper>
      <ProfileMain>
        <ProfileHeader background={background} className="profile-header">
          <Border side="left" />
          <Border side="right" />

          <ProfilePicture>
            <ProfileHandTop src={HandTop} alt="" />
            <ProfileHandTop className="right" src={HandTop} alt="" />

            <ProfileHandBottom src={HandBottom} alt="" />
            <ProfileHandBottom className="right" src={HandBottom} alt="" />

            <Avatar image={(avatar?.url || DummyPicture)} />
          </ProfilePicture>

          <NameWrapper>
            <Name>{name}</Name>
            <Email color={background}>{email}</Email>
          </NameWrapper>

          <SettingsWrapper>
            <SettingsIcon type="notifications" />
            <SettingsIcon type="settings" />
            <SettingsIcon type="signout" onClick={onExitClick} />
          </SettingsWrapper>
        </ProfileHeader>

        <ProfileBody>
          <RatingSection ratingData={ratingData} />
        </ProfileBody>
      </ProfileMain>

      <ProfileFooter>
        <Separator />

        <FooterContent>
          <Heading>Follow</Heading>

          <SocialWrapper>
            <Icon type="xing" link={footer.xingLink} />
            <Icon type="fb" link={footer.fbLink} />
            <Icon type="linkedin" link={footer.linkedinLink} />
          </SocialWrapper>

          <Heading>Contact</Heading>

          <ContactWrapper>
            <p className="phone">{footer.contactPhoneNumber}</p>
            <p className="email">{footer.contactEmail}</p>
            <p className="address">{footer.contactAddress}</p>
          </ContactWrapper>
        </FooterContent>

        <Separator />

        <Copyrights>© {new Date().getFullYear()} Appletree Innovationcy</Copyrights>
      </ProfileFooter>
    </ProfileWrapper>
  );
};

ProfileRightBlock.propTypes = {
  background: PropTypes.string,
  ratingData: PropTypes.shape({
    type: PropTypes.oneOf([ratingType.SURVEY, ratingType.COURSE, ratingType.HOME, null]).isRequired,
    data: PropTypes.shape({
      surveys: PropTypes.array,
      surveyStats: PropTypes.object,
      surveyScores: PropTypes.object
    })
  })
};

ProfileRightBlock.defaultProps = {
  background: 'blue',
  ratingData: {
    type: null
  }
};
