import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import RightArrow from '../../assets/images/arrow-right.svg';
import ClosedEyeSvg from '../../assets/images/closed-eye.svg';
import OpenEyeSvg from '../../assets/images/open-eye.svg';

import { surveyStatus } from '../../helpers/enums/surveyStatus';

const LearningCardWrapper = styled.div`
  height: 120px;
  border-top: solid #350380 7px;
  background-color: #ffffff;
  margin-bottom: 2%;
  padding: 0 20px;
  cursor: ${({ active }) => (!active ? 'default' : 'pointer')};
`;

const LearningCardRow = styled.div`
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.p`
  padding-top: 1em;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
`;

const LearningCardDescription = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8em;
  color: #808080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
`;

const CardLinkWrapper = styled.div`
  display: flex;
  img {
    margin-left: 7px;
  }
`;

const LinkText = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  color: #000000;
`;

const ClosedEye = styled.div`
  margin: 15px 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #350380;
  padding-top: 4px;
  margin-right: 7px;
`;

const OpenEye = styled.div`
  width: 45px;
  height: 45px;
  margin: 15px 0;
  padding: 0;
  background-size: cover;
  background-image: url(${OpenEyeSvg});
`;

export const LearningCard = withRouter(({ survey, cardState, courseId, order, history }) => {
  const isMaterialWatched = survey.status === null || (survey.status === surveyStatus.EVALUATED && survey.watchedMaterials.length >= 3);
  const onCardClick = useCallback(() => {
    if (!cardState.active) return;
    if (!isMaterialWatched) return;

    history.push(`/course/${courseId}/survey/${survey.id}`);
  }, [survey.id, courseId, history, cardState, isMaterialWatched]);

  return (
    <LearningCardWrapper active={cardState.active && isMaterialWatched} onClick={onCardClick}>
      <LearningCardRow>
        <Title>
          {order}. {survey.topic}
        </Title>
        {(cardState.open || survey.status === surveyStatus.RE_EVALUATED) ? (
          <OpenEye></OpenEye>
        ) : (
          <ClosedEye>
            <img src={ClosedEyeSvg} alt="not completed" />
          </ClosedEye>
        )}
      </LearningCardRow>

      <LearningCardRow>
        <LearningCardDescription>{survey.description}</LearningCardDescription>
        {cardState.active && !cardState.open  && (
          <CardLinkWrapper>
            <LinkText>Start evaluation</LinkText>
            <img src={RightArrow} alt="right arrow" />
          </CardLinkWrapper>
        )}
      </LearningCardRow>
    </LearningCardWrapper>
  );
});

LearningCard.props = {
  survey: PropTypes.shape({
    id: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    youtubeVideos: PropTypes.array.isRequired,
    videos: PropTypes.array.isRequired
  }).isRequired,
  cardState: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    reEvaluated: PropTypes.bool.isRequired
  }).isRequired,
  order: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired
};
