import React from 'react';
import PropTypes from 'prop-types';

import { LearningMaterialComponent } from './LearningMaterialsCard';

import { Carousel } from '../Carousel';


export const LearningMaterialsCarousel = ({ survey, onLearningMaterialClick }) => {
  if (survey.learningMaterials.length <= 0) return null;

  return (
      <Carousel title={"Articles"}>
        {survey.learningMaterials.map(material => {
          return (
            <LearningMaterialComponent
              onLearningMaterialClick={onLearningMaterialClick}
              key={material.id}
              material={material}
            />
          );
        })}
      </Carousel>
  );
};

LearningMaterialsCarousel.props = {
  materials: PropTypes.array.isRequired
};
