import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  Header,
  Heading,
  NavButtonsWrapper,
  PrevPage,
  NextPage,
  CarouselContent
} from './CarouselStyles';

const actions = {
  NEXT_PAGE: 'NEXT_PAGE',
  PREV_PAGE: 'PREV_PAGE'
};

const initialState = {
  page: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.NEXT_PAGE:
      return { page: state.page + 1 };
    case actions.PREV_PAGE:
      return { page: state.page - 1 };
    default:
      throw new Error('Unknown action type');
  }
};

export const Carousel = ({ children, itemHeight, itemWidth, title }) => {
  const items = React.Children.toArray(children);
  const [{ page }, dispatch] = useReducer(reducer, initialState);

  const isNextPageNotAvailable = page + 1 > items.length - 1;

  const onPrevPageClick = useCallback(() => {
    if (page === 0) return;

    dispatch({ type: actions.PREV_PAGE });
  }, [page, dispatch]);

  const onNextPageClick = useCallback(() => {
    if (isNextPageNotAvailable) return;

    dispatch({ type: actions.NEXT_PAGE });
  }, [dispatch, isNextPageNotAvailable]);

  if (items.length <= 0) return null;

  return (
    <>
      <Header>
        <Heading>{title}</Heading>
        <NavButtonsWrapper>
          <PrevPage enabled={page} onClick={onPrevPageClick} />
          <NextPage enabled={!isNextPageNotAvailable} onClick={onNextPageClick} />
        </NavButtonsWrapper>
      </Header>
      <div style={{ width: '90vw', overflow: 'hidden' }}>
        <CarouselContent page={page} itemWidth={itemWidth} itemHeight={itemHeight}>
          {items}
        </CarouselContent>
      </div>
    </>
  );
};

Carousel.props = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  itemHeight: PropTypes.number,
  itemWidth: PropTypes.number,
  title: PropTypes.string
};

Carousel.defaultProps = {
  itemHeight: 320,
  itemWidth: 205,
  title: 'Carousel'
};
