import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Question } from '../components/Survey/SurveyQuestion';
import RightArrow from '../assets/images/arrow-right.svg';

import { setSurveyDone } from '../helpers/profile/statsService';
import profile from '../helpers/profile/profileStore';

const FooterButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExitButton = styled.div`
  display: flex;
  font-size: 1em;
  font-weight: 500;
  border: none;
  color: #808080;
  margin: 2% 3%;
  outline: none;
  cursor: pointer;
`;

const FinishButton = styled(ExitButton)`
  color: #2d127a;
  p {
    font-weight: 500;
  }
  img {
    margin: auto 0;
    height: 14px;
    margin-left: 14px;
  }
`;

const QuestionnaireContainer = styled.div``;

const Questionnaire = ({ survey, history, participantId, onSurveyScoresChange, courseId }) => {
  const defaultScores = survey.questionnaire.map(question => {
    return { value: 1, questionId: question.id };
  });

  const [surveyScores, setSurveyScores] = useState(defaultScores);
  useEffect(() => {
    onSurveyScoresChange(surveyScores);
  }, [onSurveyScoresChange, surveyScores]);

  const onSurveyComplete = (participantId, surveyId, courseId, scores) => {
    setSurveyDone(participantId, surveyId, courseId, scores, profile.get().email)
      .then(() => history.push(`/course/${courseId}`))
      .catch(error => console.error(error));
  };

  const onChangeValue = (newValue, question) => {
    setSurveyScores(
      surveyScores.map(score =>
        score.questionId === question.id ? { ...score, value: newValue } : score
      )
    );
  };


  return (
    <QuestionnaireContainer>
      {survey.questionnaire.map((question, questionIndex) => (
        <Question
          questionText={`Q${questionIndex + 1}: ${question.questionText}`}
          value={surveyScores.filter(score => score.questionId === question.id)[0].value}
          changeValue={newValue => onChangeValue(newValue, question)}
          key={`Q${questionIndex + 1}: ${question.questionText}`}
        />
      ))}

      <FooterButtonsWrapper>
        <ExitButton onClick={() => history.push(`/course/${courseId}`)}>Exit</ExitButton>
        <FinishButton
          onClick={() => onSurveyComplete(participantId, survey.id, courseId, surveyScores)}
        >
          <p>Finish </p>
          <img src={RightArrow} alt="right arrow" />
        </FinishButton>
      </FooterButtonsWrapper>
    </QuestionnaireContainer>
  );
};

Questionnaire.propTypes = {
  participantId: PropTypes.string.isRequired,
  survey: PropTypes.shape({
    id: PropTypes.string.isRequired,
    questionnaire: PropTypes.arrayOf(
      PropTypes.shape({
        questionText: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
      })
    )
  }),

  onSurveyScoresChange: PropTypes.func
};

Questionnaire.defaultProps = {
  onSurveyScoresChange: () => {}
};

export default withRouter(Questionnaire);
