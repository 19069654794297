import { fromNormalToCamelCase } from '../stringUtils';

const formatChartDotValue = value => value * 0.1;
const initialChartDotValue = formatChartDotValue(0);

const chartMetaEvaluate = { color: '#2D127A', class: 'chart-0' };
const chartMetaReEvaluate = { color: '#BEE900', class: 'chart-1' };

class ProfileStore {
  get() {
    return JSON.parse(localStorage.getItem('profile'));
  }

  auth(profile) {
    if (profile && profile.email && profile.id) {
      localStorage.setItem('profile', JSON.stringify(profile));
      return true;
    } else {
      return false;
    }
  }

  isProfileLoggedIn() {
    const profile = this.get();
    return profile ? true : false;
  }

  chartDataFromSurvey(surveyStats, surveyScores) {
    const reduceFromSurveyStatsToChartData = (chartDataAccumulator, current, index) => ({
      ...chartDataAccumulator,
      [`Q${index + 1}`]: formatChartDotValue(current.value)
    });

    if (surveyStats !== null && surveyStats.hasOwnProperty('status') && surveyScores.length !== 0) {
      const evaluated = {
        data: surveyStats.firstScore.reduce(reduceFromSurveyStatsToChartData, {}),
        meta: chartMetaEvaluate
      };

      const current = {
        data: surveyScores.reduce(reduceFromSurveyStatsToChartData, {}),
        meta: chartMetaReEvaluate
      };

      return [evaluated, current];
    }

    if (surveyStats === null && surveyScores.length !== 0) {
      return [
        {
          data: surveyScores.reduce(reduceFromSurveyStatsToChartData, {}),
          meta: chartMetaEvaluate
        }
      ];
    }

    return [
      {
        data: {
          Q1: initialChartDotValue,
          Q2: initialChartDotValue,
          Q3: initialChartDotValue,
          Q4: initialChartDotValue
        },
        meta: chartMetaEvaluate
      }
    ];
  }

  scoresArithmeticMean(scores) {
    if (scores === null) return 0;

    return Math.round(scores.reduce((accum, score) => accum + score.value, 1) / scores.length);
  }

  courseChartData(surveysDataWithStats) {
    return surveysDataWithStats.reduce(
      (chartData, surveyWithStats) => {
        const currentSurveyEvaluateValue =
          surveyWithStats && surveyWithStats.status
            ? formatChartDotValue(this.scoresArithmeticMean(surveyWithStats.firstScore))
            : initialChartDotValue;

        const currentSurveyReEvaluateValue =
          surveyWithStats && surveyWithStats.status
            ? formatChartDotValue(this.scoresArithmeticMean(surveyWithStats.lastScore))
            : initialChartDotValue;

        const result = [
          {
            data: {
              [fromNormalToCamelCase(surveyWithStats.topic)]: currentSurveyEvaluateValue
            },
            meta: chartMetaEvaluate
          },
          {
            data: {
              [fromNormalToCamelCase(surveyWithStats.topic)]: currentSurveyReEvaluateValue
            },
            meta: chartMetaReEvaluate
          }
        ];

        return chartData.map((chart, i) => {
          return { ...chart, data: { ...chart.data, ...result[i].data } };
        });
      },
      [
        { data: {}, meta: chartMetaEvaluate },
        { data: {}, meta: chartMetaReEvaluate }
      ]
    );
  }
}

const profile = new ProfileStore();

export default profile;
